import "@babel/polyfill";
import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
// import VueMaterial from 'vue-material';
import BootstrapVue from "bootstrap-vue";
import {
    MdCard,
    MdField,
    MdButton,
    MdProgress,
    MdCheckbox,
    MdRadio,
    MdDialog,
    MdDialogAlert,
} from "vue-material/dist/components";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import vueCustomElement from "vue-custom-element";

// Vue.use(VueMaterial);
Vue.use(Vuex);
Vue.use(vueCustomElement);
Vue.use(BootstrapVue);
Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdButton);
Vue.use(MdProgress);
Vue.use(MdCheckbox);
Vue.use(MdRadio);
Vue.use(MdDialog);
Vue.use(MdDialogAlert);

Vue.config.productionTip = false;

const store = new Vuex.Store({
    state: {
        conf: {},
        socialToken: "",
        socialType: "",
        socialId: "",
        disableSocialFlag: false,
        disableSocialAppBrowser: false
    },
    getters: {
        conf: (state) => {
            return state.conf;
        },
        socialToken: (state) => {
            return state.socialToken;
        },
        socialType: (state) => {
            return state.socialType;
        },
        socialId: (state) => {
            return state.socialId;
        },
        disableSocialFlag: (state) => {
            return state.disableSocialFlag;
        },
        disableSocialAppBrowser: (state) => {
            return state.disableSocialAppBrowser;
        },
    },
    mutations: {
        updateConf: (state, conf) => {
            state.conf = conf;
        },
        setSocialToken: (state, data) => {
            state.socialToken = data;
        },
        setSocialType: (state, data) => {
            state.socialType = data;
        },
        setSocialId: (state, data) => {
            state.socialId = data;
        },
        setDisableSocialFlag: (state, data) => {
            state.disableSocialFlag = data;
        },
        setDisableSocialAppBrowser: (state, data) => {
            state.disableSocialAppBrowser = data;
        },
    },
});

new Vue({
    router,
    render: (h) => h(App),
    store,
}).$mount("#app");
