







import { Component, Vue } from "vue-property-decorator";
import { CMAPI } from "@/lib/server.api";

@Component({})
export default class LogoutPage extends Vue {
    public created() {
        (window as any).gtag("config", "UA-123331887-12", {
            page_path: "/SignOut"
        });
    }

    public async mounted() {
        try {
            await CMAPI.logout();
            const destination: any =
                this.$route.query.hop ||
                encodeURIComponent(<any>process.env.VUE_APP_SSO_URL);
            window.location.replace(
                `https://codemotionworld.com/deletecmid.php?destination=${destination}`
            );
        } catch (e) {
            window.location.replace(<string>this.$route.query.hop);
        }
    }
}
