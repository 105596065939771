



























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
    CMAPI as API,
    CMServerErrorList,
    CM_INVALID_LOGIN_DATA_ERROR,
    CM_INACTIVE_ACCOUNT_ERROR,
} from "../lib/server.api";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { setTimeout } from "timers";
import { EventBus, EventBusEvents } from "@/lib/event-bus.bus";
import SocialLogin from "@/components/SocialLogin.vue";
import CmInput from "./CmInput.vue";

@Component({
    components: {
        SocialLogin,
        CmInput,
    },
    mixins: [validationMixin],
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        },
    },
})
export default class LoginForm extends Vue {
    public email: string = "";
    public password: string = "";
    public destination: string = "";

    public operating: boolean = false;
    public loadingSocial: boolean = false;
    public hasLoginError: boolean = false;

    public showPass: boolean = false;

    public embedded: boolean = false;
    public withNavigation: boolean = false;
    public errorMessage: string = "";

    private embTag: string[] | undefined = undefined;

    public created() {
        this.embedded = this.$route.query.e ? true : false;
        this.withNavigation = this.$route.query.n ? true : false;
        this.embTag = this.$route.query.cmtag
            ? (<string>this.$route.query.cmtag).split(",")
            : undefined;
    }

    public mounted() {
        this.destination = <string>this.$route.query.hop || "";
        if (this.embedded) {
            window.parent.postMessage(
                {
                    message: "loaded",
                    data: {
                        h: this.$store.getters.disableSocialFlag ? 360 : 510,
                    },
                },
                "*"
            );
        }
    }

    public async login(): Promise<void> {
        this.operating = true;
        this.hasLoginError = false;
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.operating = false;
            return;
        }

        setTimeout(async () => {
            try {
                const res = await API.login(
                    this.email,
                    this.password,
                    this.destination,
                    this.embTag
                );
                this.hasLoginError = false;
                let method = "SSO";
                if (this.embedded) {
                    method = "EMBEDDED";
                    if (this._hasTag()) {
                        method += "_" + this._getTagString();
                    }
                    (window as any).gtag("event", "login", { method });
                    setTimeout(function() {
                        window.parent.postMessage(
                            {
                                message: "loginCompleted",
                                data: {
                                    uid: res.user.id,
                                    email: res.user.email,
                                },
                            },
                            "*"
                        );
                    }, 500);
                } else {
                    method = "SSO";
                    if (this._hasTag()) {
                        method += "_" + this._getTagString();
                    }
                    (window as any).gtag("event", "login", { method });
                    EventBus.$emit(EventBusEvents.LOGIN_SUCCESS, res);
                }
            } catch (e) {
                this.hasLoginError = true;
                if (e.name === "CMServerErrorList") {
                    this.__handleServerError(e);
                }
                this.operating = false;
            }
        }, 100);
    }

    public getValidationClass(fieldName) {
        const field = this.$v[fieldName];
        if (field) {
            return {
                "md-invalid":
                    (field.$invalid && field.$dirty) || this.hasLoginError,
            };
        }
    }

    private _popupAction() {
        window.opener.postMessage(
            { message: "loginCompleted", data: null },
            "*"
        );
        window.close();
    }

    private _iframeAction() {
        window.parent.postMessage(
            { message: "loginCompleted", data: null },
            "*"
        );
    }

    private _hasTag(): boolean {
        return (
            this.embTag !== undefined &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        );
    }

    private _getTagString(): string {
        if (
            this.embTag &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        ) {
            return this.embTag[0];
        }
        return "TAG";
    }

    private __handleServerError(error: CMServerErrorList) {
        this.errorMessage = "";
        error.errors.forEach((e) => {
            switch (e.name) {
                case CM_INVALID_LOGIN_DATA_ERROR:
                    this.errorMessage =
                        "Wrong email or password. Are you sure your email is already registered? If so, you must recover your password! Otherwise, you are welcome to create a new account.";
                    break;
                case CM_INACTIVE_ACCOUNT_ERROR:
                    this.errorMessage =
                        "Your email is already registered but not confirmed yet. Just recover your password";
                    break;
                default:
                    this.errorMessage =
                        "Server error, please try again or contact the support";
                    break;
            }
        });
    }
}
