






import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@/components/LoginForm.vue";

@Component({
    components: {
        LoginForm,
    },
})
export default class LoginPage extends Vue {
    public created() {
        (window as any).gtag("config", "UA-123331887-12", {
            page_path: "/SignIn",
        });
    }
}
