





























































































































































import { Component, Vue } from "vue-property-decorator";
import { CMAPI as API } from "../lib/server.api";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { EventBus, EventBusEvents } from "@/lib/event-bus.bus";

@Component({
    mixins: [validationMixin],
    validations: {
        formPolicies: {
            $each: {
                name: {
                    required,
                },
                agree: {
                    isSet(value) {
                        return value >= 0;
                    },
                },
                text: {
                    required,
                },
                mandatory: {
                    required,
                },
                link: {},
            },
        },
    },
})
export default class ConfirmRegistration extends Vue {
    public success: boolean = false;
    public loadingPolicies: boolean = false;
    private embTag: string[] | undefined = undefined;
    public errorMessage: string = "";
    public hasError: boolean = false;
    public formPolicies: any = [];
    public loading: boolean = false;
    private embedded = false;

    public async created() {
        this.embedded = this.$route.query.e ? true : false;
        this.embTag = this.$route.query.cmtag
            ? (<string>this.$route.query.cmtag).split(",")
            : undefined;
        (window as any).gtag("config", "UA-123331887-12", {
            page_path: "/Confirm",
        });
        if (this.socialToken()) {
            this.loadingPolicies = true;
            try {
                const policies = await API.getPolicies();
                this.formPolicies = policies.map((policy) => ({
                    id: policy.id,
                    name: policy.name,
                    text: policy.description,
                    mandatory: policy.required,
                    link: policy.link,
                    agree: -1,
                }));
            } catch (error) {
                this.errorMessage =
                    "Something is not working fine. Try with another browser or try again later.";
            } finally {
                this.loadingPolicies = false;
            }
        } else {
            try {
                this.success = await API.confirmRegistration(
                    <string>this.$route.query.t,
                    <string>this.$route.query.cmtag,
                    <string>this.$route.query.hop
                );
                let label = "SSO";
                if (this._hasTag()) {
                    label += "_" + this._getTagString();
                }
                (window as any).gtag("event", "sign_up_confirm", {
                    event_category: "engagement",
                    event_label: label,
                });
            } catch (e) {
                this.success = false;
            }
        }
    }

    async setPolicies() {
        this.loading = true;

        this.$v.$reset();
        this.errorMessage = "";
        this.hasError = false;

        this.$v.$touch();
        if (this.$v.$invalid) {
            this.loading = false;
            return;
        }
        const payload = {
            policies: this.formPolicies.map((p) => ({
                id: p.id,
                agree: p.agree,
            })),
            cmtag: this.embTag,
            hop: <string>this.$route.query.hop || undefined,
            utm: {
                utmSource: <string>this.$route.query.utmSource || undefined,
                utmMedium: <string>this.$route.query.utmMedium || undefined,
                utmCampaign: <string>this.$route.query.utmCampaign || undefined,
                utmUrl: <string>this.$route.query.utmUrl || undefined,
                utmContent: <string>this.$route.query.utmContent || undefined,
            },
        };
        try {
            const res = await API.confirmSocialRegistration(
                <string>this.socialToken(),
                <string>this.socialType(),
                <string>this.socialId(),
                payload
            );
            if (this.embedded) {
                setTimeout(function() {
                    window.parent.postMessage(
                        {
                            message: "registrationCompleted",
                            data: {
                                uid: res.user.id,
                                email: res.user.email,
                            },
                        },
                        "*"
                    );
                }, 500);
            } else {
                EventBus.$emit(EventBusEvents.SOCIAL_LOGIN_SUCCESS, res);
            }
        } catch (error) {
            this.hasError = true;
            this.errorMessage =
                "Server error, please try again or contact the support";
            console.error(error);
        } finally {
            this.loading = false;
        }
    }
    private socialToken() {
        return this.$store.getters.socialToken || this.$route.query.st;
    }
    private socialType() {
        return this.$store.getters.socialType || this.$route.query.social;
    }
    private socialId() {
        return this.$store.getters.socialId || this.$route.query.sid;
    }

    private _hasTag(): boolean {
        return (
            this.embTag !== undefined &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        );
    }

    private _getTagString(): string {
        if (
            this.embTag &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        ) {
            return this.embTag[0];
        }
        return "";
    }
}
