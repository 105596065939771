var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.registerSuccess)?_c('form',{staticClass:"md-layout md-alignment-center-center",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('md-card',{staticClass:"md-layout-item md-elevation-5 cm-form-card",class:{ operating: _vm.operating },attrs:{"id":"register-card"}},[_c('md-card-header',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"tab-entry active"},[_vm._v("Sign Up")]),_c('router-link',{staticClass:"tab-entry",attrs:{"to":{
                        path: '/',
                        query: this.$route.query,
                    }}},[_vm._v("Sign In")])],1),_c('md-card-content',{staticClass:"pb-0"},[_c('social-login',{attrs:{"disabled":_vm.loadingPolicies || _vm.operating},on:{"loading":function($event){_vm.loadingPolicies = $event}}}),(_vm.loadingPolicies)?_c('div',{staticClass:"\n                        d-flex\n                        align-items-center\n                        justify-content-center\n                        py-3\n                    "},[_c('b-spinner')],1):(
                        _vm.formPolicies &&
                        _vm.formPolicies.length &&
                        !_vm.hasRegisterError
                    )?_c('div',[_c('md-field',{class:_vm.getValidationClass('email')},[_c('cm-input',{attrs:{"name":"newEmail","id":"newEmail","placeholder":"Email","autocomplete":"off","disabled":_vm.operating},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(!_vm.$v.email.required)?_c('span',{staticClass:"md-error"},[_vm._v("The email is required")]):(!_vm.$v.email.email)?_c('span',{staticClass:"md-error"},[_vm._v("Invalid email format")]):_vm._e()],1),_c('md-field',{class:_vm.getValidationClass('password')},[_c('cm-input',{attrs:{"placeholder":"Password","id":"userPassword","name":"userPassword","autocomplete":"new-password","type":_vm.showPass ? 'text' : 'password',"disabled":_vm.operating},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}},[_c('div',{staticClass:"icon-right",on:{"click":function($event){_vm.showPass = !_vm.showPass}}},[_c('md-icon',[_vm._v(_vm._s(_vm.showPass ? "visibility" : "visibility_off"))])],1)]),(!_vm.$v.password.required)?_c('span',{staticClass:"md-error"},[_vm._v("The password is required")]):_vm._e(),(!_vm.$v.password.minLength)?_c('span',{staticClass:"md-error"},[_vm._v("The password must be at least "+_vm._s(_vm.$v.password.$params.minLength.min)+" character")]):_vm._e()],1),_c('div',{staticClass:"cm-error",class:{ hasError: _vm.hasRegisterError },domProps:{"innerHTML":_vm._s(_vm.errorMessage)}}),_vm._l((_vm.$v.formPolicies.$each
                            .$iter),function(policy,index){return _c('div',{key:index,staticClass:"policy-box"},[(policy.mandatory.$model)?_c('div',{staticClass:"policy-checkbox align-items-center mb-2",class:{
                                'md-invalid-policy': policy.$invalid,
                            }},[_c('md-checkbox',{attrs:{"name":policy.name.$model,"value":1,"disabled":_vm.operating,"id":"policy"},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}}),_c('label',{staticClass:"policy-text",attrs:{"for":"policy"},domProps:{"innerHTML":_vm._s(policy.text.$model)}})],1):_vm._e(),(!policy.mandatory.$model)?_c('div',{staticClass:"policy-radio"},[_c('div',{staticClass:"policy-text",domProps:{"innerHTML":_vm._s(policy.text.$model)}}),_c('md-radio',{attrs:{"name":policy.name.$model,"value":1,"disabled":_vm.operating},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}},[_vm._v("Yes")]),_c('md-radio',{attrs:{"name":policy.name.$model,"value":0,"disabled":_vm.operating},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}},[_vm._v("No")])],1):_vm._e(),(
                                policy.agree.$dirty && !policy.agree.isSet
                            )?_c('div',{staticClass:"text-left mb-3 md-error mt-n2"},[_c('span',[_vm._v(_vm._s(policy.mandatory.$model ? "You must accept our Terms and Conditions" : "Please set your preference"))])]):_vm._e()])})],2):_vm._e(),_c('div',{staticClass:"cm-error",class:{ hasError: _vm.hasRegisterError },domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})],1),_c('md-card-actions',[_c('div',{staticClass:"w-100",attrs:{"id":"signup-btn"}},[_c('md-button',{staticClass:"md-primary md-button md-raised w-100",attrs:{"type":"submit","disabled":!_vm.formPolicies ||
                            !_vm.formPolicies.length ||
                            _vm.loadingPolicies ||
                            _vm.operating}},[_vm._v("Sign up")])],1)]),(_vm.operating)?_c('md-progress-bar',{staticClass:"cm-action-progress",attrs:{"md-mode":"indeterminate","id":"login-progress"}}):_vm._e()],1)],1):_vm._e(),(_vm.registerSuccess)?_c('div',{staticClass:"md-layout md-alignment-center-center"},[_c('md-card',{staticClass:"md-layout-item md-elevation-5 cm-form-card"},[_c('md-icon',{staticClass:"email-icon-sent"},[_vm._v("email")]),_c('div',{staticClass:"txt-send-email px-4"},[_vm._v(" Great! Check your email "),_c('strong',[_vm._v(_vm._s(_vm.email))]),_vm._v(" to confirm your account. "),_c('br'),_c('br'),(!_vm.resent)?_c('div',[_vm._v(" Didn't receive the email? "),_c('strong',{staticClass:"link",on:{"click":_vm.resend}},[_vm._v("Resend")]),_vm._v(" or check your spam folder. ")]):_c('div',[_vm._v("Mail just sent! Take a look to your inbox!")])])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }