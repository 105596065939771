export class CMPolicyRule {

    /** @public */
    public id: string;

    /** @public */
    public name: string;

    /** @public */
    public displayName: string;

    /** @public */
    public description: string;

    /** @public */
    public link: string;

    /** @public */
    public active: boolean;

    /** @public */
    public required: boolean;

    constructor() {
        this.id = '';
        this.name = '';
        this.active = false;
        this.displayName = '';
        this.description = '';
        this.link = '';
        this.required = false;
    }

    public fromData(data: RawPolicyRuleData): CMPolicyRule {
        this.id = data.id || '';
        this.name = data.name || '';
        this.active = data.active || false;
        this.displayName = data.displayName || '';
        this.description = data.description || '';
        this.link = data.link || '';
        this.required = data.required || false;
        return this;
    }
}

export interface RawPolicyRuleData {
    id?: string;
    name?: string;
    displayName?: string;
    description?: string;
    link?: string;
    active?: boolean;
    required?: boolean;
}
