







































































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { CMAPI as API } from "../lib/server.api";
import { EventBus, EventBusEvents } from "@/lib/event-bus.bus";
import CmInput from "@/components/CmInput.vue";

@Component({
    components: {
        CmInput,
    },
    mixins: [validationMixin],
    validations: {
        email: {
            required,
            email,
        },
    },
})
export default class RecoverPasswordPage extends Vue {
    public email: string = "";
    public operating: boolean = false;
    public sent: boolean = false;
    public hasError: boolean = false;
    public resent: boolean = false;

    public created() {
        (window as any).gtag("config", "UA-123331887-12", {
            page_path: "/RecoverPassword",
        });
    }

    public getValidationClass(fieldName) {
        const field = this.$v[fieldName];
        if (field) {
            return {
                "md-invalid": field.$invalid && field.$dirty,
            };
        }
    }

    public async recover(): Promise<void> {
        this.operating = true;
        if (this.sent !== true) {
            this.$v.$touch();
            this.hasError = false;
            if (this.$v.$invalid) {
                this.operating = false;
                return;
            }
        }
        try {
            const res = await API.recoverPassword(this.email);
            if (this.sent !== true) {
                this.sent = true;
            } else {
                this.resent = true;
            }
        } catch (e) {
            console.error(e);
            this.sent = false;
            this.hasError = true;
        } finally {
            this.operating = false;
        }
    }
}
