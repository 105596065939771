




import { Component, Prop, Vue } from "vue-property-decorator";
import { CMAPI as API } from "@/lib/server.api";
import { EventBus, EventBusEvents } from "@/lib/event-bus.bus";

@Component({})
export default class GoogleButton extends Vue {
    private auth2;
    private embedded = false;

    mounted() {
        this.embedded = this.$route.query.e ? true : false;

        setTimeout(() => {
            (<any>window).google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: this.handleCredentialResponse,
            auto_select: true
        });

        (<any>window).google.accounts.id.renderButton(
            this.$refs.googleLoginBtn, {
                text: 'Login',
                size: 'large',
                width: '400', 
                theme: 'outline',
                locale: 'en_US'
            }
        )
        }, 300);
        
    }

    async handleCredentialResponse(response:any) {
    
        let utm = {
            utmSource: <string>this.$route.query.utm_source || undefined,
            utmMedium: <string>this.$route.query.utm_medium || undefined,
            utmCampaign: <string>this.$route.query.utm_campaign || undefined,
            utmUrl: <string>this.$route.query.utm_url || undefined,
            utmContent: <string>this.$route.query.utm_content || undefined,
        };

        const res = await API.googleSocialLogin(
            response.credential,
            this.$route.query.hop,
            this.$route.query.cmtag,
            Object.keys(utm)
                .filter((el) => utm[el])
                .map((key) => key + "=" + utm[key])
                .join("&")
        );

        if (res.redirect.includes("Confirm")) {
            this.$store.commit("setSocialToken", res.st);
            this.$store.commit("setSocialType", res.social);
            this.$store.commit("setSocialId", res.sid);
            this.$router.push({
                path: "/Confirm",
                query: {
                    ...utm,
                    cmtag: this.$route.query.cmtag,
                    hop: this.$route.query.hop,
                },
            });
        } else {
            if (this.embedded) {
                setTimeout(function() {
                    window.parent.postMessage(
                        {
                            message: "loginCompleted",
                            data: {
                                uid: res.user.id,
                                email: res.user.email,
                            },
                        },
                        "*"
                    );
                }, 500);
            } else {
                EventBus.$emit(EventBusEvents.SOCIAL_LOGIN_SUCCESS, res);
            }
        }
      }
}
