var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"confirm-registration-page"},[_c('div',{staticClass:"md-layout md-alignment-center-center"},[_c('md-card',{staticClass:"md-layout-item md-elevation-5"},[(_vm.loadingPolicies)?_c('div',{staticClass:"d-flex align-items-center justify-content-center py-5"},[_c('b-spinner')],1):(_vm.formPolicies.length)?_c('form',{staticClass:"md-layout md-alignment-center-center px-4 px-md-0",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.setPolicies($event)}}},[_c('md-card-header',{staticClass:"pt-0 px-0"},[_c('h1',{staticClass:"md-title"},[_vm._v("Set your preferences")])]),_c('md-card-content',[_vm._l((_vm.$v.formPolicies.$each
                            .$iter),function(policy,index){return _c('div',{key:index,staticClass:"policy-box"},[(policy.mandatory.$model)?_c('div',{staticClass:"\n                                    policy-checkbox\n                                    align-items-center\n                                    mb-2\n                                ",class:{
                                'md-invalid-policy': policy.$invalid,
                            }},[_c('md-checkbox',{attrs:{"name":policy.name.$model,"value":1,"id":"policy"},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}}),_c('label',{staticClass:"policy-text",attrs:{"for":"policy"},domProps:{"innerHTML":_vm._s(policy.text.$model)}})],1):_vm._e(),(!policy.mandatory.$model)?_c('div',{staticClass:"policy-radio"},[_c('div',{staticClass:"policy-text",domProps:{"innerHTML":_vm._s(policy.text.$model)}}),_c('md-radio',{attrs:{"name":policy.name.$model,"value":1},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}},[_vm._v("Yes")]),_c('md-radio',{attrs:{"name":policy.name.$model,"value":0},model:{value:(policy.agree.$model),callback:function ($$v) {_vm.$set(policy.agree, "$model", $$v)},expression:"policy.agree.$model"}},[_vm._v("No")])],1):_vm._e(),_c('div',{staticClass:"text-left md-error mt-n1"},[(
                                    !policy.agree.isSet &&
                                        policy.agree.$dirty
                                )?_c('div',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(policy.mandatory.$model ? "You must accept our Terms and Conditions" : "Please set your preference")+" ")]):_vm._e()])])}),_c('div',{staticClass:"cm-error",class:{ hasError: _vm.hasError }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],2),_c('md-card-actions',{staticClass:"w-100"},[_c('div',{staticClass:"w-100",attrs:{"id":"signup-btn"}},[_c('md-button',{staticClass:"md-primary md-raised w-100",attrs:{"type":"submit","disabled":_vm.loadingPolicies || _vm.loading}},[_vm._v("Complete registration")])],1)])],1):(_vm.success)?[_c('md-icon',[_vm._v("check_circle")]),_c('md-card-content',{staticClass:"p-3"},[_c('div',{staticClass:"txt-send-email"},[_vm._v(" You have verified successfully your email. Now you can login with your credentials. ")]),_c('div',{staticClass:"wrap-button-login"},[_c('md-button',{staticClass:"md-button md-primary md-raised w-100 m-0",attrs:{"to":{
                                path: '/SignIn',
                                query: this.$route.query,
                            },"type":"submit"}},[_vm._v("Login")])],1)])]:[_c('md-icon',[_vm._v("error")]),_c('md-card-content',{staticClass:"p-3"},[_c('div',{staticClass:"txt-send-email"},[_c('b',[_vm._v("We can't verify your account.")]),_vm._v(" The token isn't valid, has expired or maybe you have already activated your account. ")]),_c('div',{staticClass:"wrap-button-login"},[_c('md-button',{staticClass:"md-button md-primary md-raised w-100 m-0",attrs:{"to":{
                                path: '/SignIn',
                                query: this.$route.query,
                            },"type":"submit"}},[_vm._v("Back to login form")])],1)])],(_vm.loading)?_c('md-progress-bar',{staticClass:"cm-action-progress",attrs:{"md-mode":"indeterminate","id":"login-progress"}}):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }