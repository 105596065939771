import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from './views/LoginPage.vue';
import RegisterPage from './views/RegisterPage.vue';
import LogoutPage from './views/LogoutPage.vue';
import RecoverPasswordPage from './views/RecoverPasswordPage.vue';
import ResetPasswordPage from './views/ResetPasswordPage.vue';
import ConfirmRegistration from './views/ConfirmRegistration.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: LoginPage,
    },
    {
      path: '/SignIn',
      name: 'login',
      component: LoginPage,
    },
    {
      path: '/SignUp',
      name: 'signup',
      component: RegisterPage,
    },
    {
      path: '/SignOut',
      name: 'logout',
      component: LogoutPage,
    },
    {
      path: '/RecoverPassword',
      name: 'recoverpassword',
      component: RecoverPasswordPage,
    },
    {
      path: '/ResetPassword',
      name: 'resetpassword',
      component: ResetPasswordPage,
    },
    {
      path: '/Confirm',
      name: 'confirmRegistration',
      component: ConfirmRegistration,
    },
  ],
});
