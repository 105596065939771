








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
    public content: string = '';
    public confirmText: string = 'OK';

    public show: boolean = false;

    public showModal(content: string, confirmText: string) {
        this.content = content;
        this.confirmText = confirmText;
        this.show = true;
    }

    public hideModal() {
        this.show = false;
        this.content = '';
        this.confirmText = '';
    }
}
