

























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import {
    CMAPI as API,
    CMServerErrorList,
    CM_DUPLICATED_EMAIL_ERROR,
    CM_INVALID_EMAIL_ERROR,
    CM_INVALID_PASSWORD_ERROR,
    CM_INVALID_REF_ID_ERROR,
    CM_INVALID_LOGIN_DATA_ERROR,
    CMPolicyAgree,
} from "../lib/server.api";
import SocialLogin from "@/components/SocialLogin.vue";
import CmInput from "@/components/CmInput.vue";
import { EventBus, EventBusEvents } from "@/lib/event-bus.bus";

@Component({
    components: {
        SocialLogin,
        CmInput,
    },
    mixins: [validationMixin],
    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLength: minLength(8),
        },
        formPolicies: {
            $each: {
                name: {
                    required,
                },
                agree: {
                    isSet(value) {
                        return value !== null && value >= 0;
                    },
                },
                text: {
                    required,
                },
                mandatory: {
                    required,
                },
                link: {},
            },
        },
    },
})
export default class RegisterForm extends Vue {
    public email: string = "";
    public password: string = "";
    public confirmPassword: string = "";
    public formPolicies: CMFormPolicy[] = [];
    public resent: boolean = false;
    public showPass: boolean = false;
    public operating: boolean = false;
    public hasRegisterError: boolean = false;
    public registerSuccess: boolean = false;
    public errorMessage: string = "";
    public embedded: boolean = false;
    public bounceOut: boolean = false;
    public withNavigation: boolean = false;
    public utm: any = {};
    public loadingPolicies: boolean = false;
    private embTag: string[] | undefined = undefined;
    private skipRegisterSuccess: boolean = false;

    public async created() {
        this.embedded = this.$route.query.e ? true : false;
        this.withNavigation = this.$route.query.n ? true : false;
        this.bounceOut =
            this.$route.query.bo && this.$route.query.bo.toString() === "1"
                ? true
                : false;
        this.embTag = this.$route.query.cmtag
            ? (<string>this.$route.query.cmtag).split(",")
            : undefined;
        this.utm = {
            utmSource: this.$route.query.utm_source,
            utmMedium: this.$route.query.utm_medium,
            utmCampaign: this.$route.query.utm_campaign,
            utmUrl: this.$route.query.utm_url,
        };
        this.skipRegisterSuccess = this.$route.query.skipRegisterSuccess ? true : false;
    }
    public async mounted() {
        if (this.embedded) {
            window.parent.postMessage(
                { message: "loaded", data: { h: this.$store.getters.disableSocialFlag ? 240 : 400 } },
                "*"
            );
        }
        this.loadingPolicies = true;

        try {
            const policies = await API.getPolicies();
            this.formPolicies = new Array();
            policies.forEach((policy) => {
                this.formPolicies.push({
                    id: policy.id,
                    name: policy.name,
                    text: policy.description,
                    mandatory: policy.required,
                    link: policy.link,
                    agree: -1,
                });
            });
            if (this.embedded) {
                window.parent.postMessage(
                    { message: "loaded", data: { h: this.$store.getters.disableSocialFlag ? 600 : 800 } },
                    "*"
                );
            }
        } catch (error) {
            this.hasRegisterError = true;
            this.errorMessage =
                "Something is not working fine. Try with another browser or try again later.";
        } finally {
            this.loadingPolicies = false;
        }
    }

    public async register() {
        this.operating = true;
        this.registerSuccess = false;
        
        this.__restErrors();
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.operating = false;
            return;
        }
        setTimeout(async () => {
            try {
                const res = await API.register(
                    this.email,
                    this.password,
                    this.password,
                    this.formPolicies.map<CMPolicyAgree>((p) => ({
                        id: p.id,
                        agree: p.agree,
                    })),
                    this.embTag,
                    <string>this.$route.query.hop || undefined,
                    this.utm
                );
                this.hasRegisterError = false;
                let method = "SSO";
                if (this.embedded && this.bounceOut) {
                    method = "EMBEDDED";
                    if (this._hasTag()) {
                        method += "_" + this._getTagString();
                    }
                    (window as any).gtag("event", "sign_up", { method });
                    setTimeout(function () {
                        window.parent.postMessage(
                            {
                                message: "registrationCompleted",
                                data: {
                                    uid: res.user.id,
                                    email: res.user.email,
                                },
                            },
                            "*"
                        );
                    }, 500);
                } else {
                    method = "SSO";
                    if (this._hasTag()) {
                        method += "_" + this._getTagString();
                    }
                    (window as any).gtag("event", "sign_up", { method });
                    this.registerSuccess = true;

                    if(this.skipRegisterSuccess){
                        // setTimeout(function () {
                            console.log('res', res);
                            res.redirect = this.$route.query.hop;
                            EventBus.$emit(EventBusEvents.LOGIN_SUCCESS, res);
                        // }, 2000);
                    }
                }
            } catch (e) {
                if (e.errors) {
                    this.__handleServerError(e);
                } else {
                    this.errorMessage = e;
                }
                this.hasRegisterError = true;
                this.operating = false;
            }
        }, 100);
    }

    public getValidationClass(fieldName) {
        const field = this.$v[fieldName];
        if (field) {
            return {
                "md-invalid": field.$invalid && field.$dirty,
            };
        }
    }

    public async resend(): Promise<void> {
        try {
            await API.resendActivation(this.email);
            this.resent = true;
        } catch (e) {
            console.error(e);
            this.resent = false;
        }
    }

    private _popupAction() {
        window.opener.postMessage(
            { message: "registrationCompleted", data: null },
            "*"
        );
        window.close();
    }

    private _iframeAction() {
        window.parent.postMessage(
            { message: "registrationCompleted", data: null },
            "*"
        );
    }

    private __restErrors() {
        this.$v.$reset();
        this.hasRegisterError = false;
        this.errorMessage = "";
    }

    private __handleServerError(error: CMServerErrorList) {
        this.errorMessage = "";
        error.errors.forEach((e) => {
            switch (e.name) {
                case CM_DUPLICATED_EMAIL_ERROR:
                case CM_INVALID_PASSWORD_ERROR:
                case CM_INVALID_REF_ID_ERROR:
                case CM_INVALID_EMAIL_ERROR:
                case CM_INVALID_LOGIN_DATA_ERROR:
                    this.errorMessage += "; " + e.message;
                    break;
                default:
                    this.errorMessage +=
                        "Server error, please try again or contact support";
                    break;
            }
        });
        if (!this.errorMessage) {
            this.errorMessage =
                "Something went wrong. Please try again later or contact support. Code: " +
                error;
        }
    }

    private _hasTag(): boolean {
        return (
            this.embTag !== undefined &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        );
    }

    private _getTagString(): string {
        if (
            this.embTag &&
            Array.isArray(this.embTag) &&
            this.embTag.length > 0
        ) {
            return this.embTag[0];
        }
        return "TAG";
    }
}

interface CMFormPolicy {
    id: string;
    name: string;
    text: string;
    mandatory: boolean;
    link: string;
    agree: number;
}
