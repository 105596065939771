<template>
    <div class="input-group" :class="{ 'has-label': label }">
        <div :for="id" class="label" v-if="label">{{ label }}</div>
        <input class="input-area" v-model="input" v-bind="$attrs" :id="id" />
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ["id", "label", "value"],
    computed: {
        input: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";
.input-group {
    position: relative;
    * {
        transition: all 0.3s ease;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .label {
        font-size: 14px;
        line-height: 20px;
        color: $secondary;
        font-weight: 500;
        margin-bottom: 4px;
    }
    input {
        border-radius: 10px;
        width: 100%;
        height: 40px;
        border: 1px solid #b2b2b2;
        padding: 12px 14px;

        &:focus {
            border: 2px solid $secondary !important;
        }
        &:focus-visible {
            outline-color: transparent;
        }
    }
    .icon-right {
        position: absolute;
        right: 14px;
        bottom: 15px;
        cursor: pointer;
        &:hover {
            .md-icon {
                color: $secondary !important;
            }
        }
    }
    &.has-label {
        .icon-right {
            bottom: 8px;
        }
    }
}
</style>
