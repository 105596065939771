





import { Component, Vue } from "vue-property-decorator";
import RegisterForm from "@/components/RegisterForm.vue";

@Component({
    components: {
        RegisterForm,
    },
})
export default class RegisterPage extends Vue {
    public created() {
        (window as any).gtag("config", "UA-123331887-12", {
            page_path: "/SignUp",
        });
    }
}
